import React from 'react';
import {Redirect, Link, withRouter} from 'react-router-dom';
import {
    Switch,
    Space,
    Select,
    Input,
    Button,
    Table,
    Modal,
    Row,
    Col,
    Form,
    message,
    Checkbox,
    Radio,
    Pagination,
    Tag,
    Popconfirm,
    Tooltip,
    DatePicker
} from 'antd';
import store from "../store/store.js";
import {FormOutlined} from '@ant-design/icons'
import moment from 'moment';
import Add from './add';
import Kucun from './kc_index.js';
import Jiesuan from './jiesuan'
import Dcdzd from './dcdzd.js'

const {RangePicker} = DatePicker;
const {Option} = Select;
const CheckboxGroup = Checkbox.Group;
class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            month_react: [
                {id: 1, name: '1月'},
                {id: 2, name: '2月'},
                {id: 3, name: '3月'},
                {id: 4, name: '4月'},
                {id: 5, name: '5月'},
                {id: 6, name: '6月'},
                {id: 7, name: '7月'},
                {id: 8, name: '8月'},
                {id: 9, name: '9月'},
                {id: 10, name: '10月'},
                {id: 11, name: '11月'},
                {id: 12, name: '12月'},
            ]
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }

    componentDidMount() {
        // this.props.NAV_CHANGE('/jckgl', '物资收发')
        console.log('iswz:' + this.props.match.params.iswz)
        this.props.NAV_CHANGE('/jckgl'+(this.props.match.params.iswz ? ('/' + this.props.match.params.iswz) : ''), this.props.match.params.iswz ? '物资统计':'物资收发')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize', this.tableHeight)
    }

    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 280 - selheight
        that.setState({
            tableHeight: yheight
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.tableHeight)
    }

    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, {[name]: dateString})
        this.setState({
            newcondition: data
        })
    }

    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
        setTimeout(() => {
            if (name == 'cj' || name == 'gg' || name == 'xh' || name == 'gys') {
                that.getFilterInfo()
            }
        })
    }

    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }

    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, {[name]: value})
        this.setState({
            newcondition: data
        })
        setTimeout(() => {
            if (name == 'pm_keywords') {
                that.getFilterInfo()
            }
        })
    }

    // 获取品名筛选项
    getFilterInfo() {
        let that = this
        let arr = {}
        let {pm_keywords, cj, gg, xh, gys} = that.state.newcondition
        fetch('/Ysjsfhz/filter_pm', {
            method: "POST",
            body: JSON.stringify({
                pm:pm_keywords,
                cj,
                gg,
                xh,
                gys
            })
        })
            .then(res => res.json())
            .then(data => {
                that.setState({
                    kucun_cj_react: data.kucun_cj_react ? data.kucun_cj_react : [],
                    kucun_gg_react: data.kucun_gg_react ? data.kucun_gg_react : [],
                    kucun_xh_react: data.kucun_xh_react ? data.kucun_xh_react : [],
                    kucun_gys_react: data.kucun_gys_react ? data.kucun_gys_react : [],
                })
            })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({selectedRowKeys});
    };

    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, {current: page, pagesize: pagesize})
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }

    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index}
                        disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }

    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, {current: 1, pagesize: old.pagesize})
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        arr.iswz = that.props.match.params.iswz ? that.props.match.params.iswz : 0
        fetch('/Ysjsfhz/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count),
                    month_react: data.month_react ? data.month_react : [],
                    chid_react: data.chid_react ? data.chid_react : [],
                    pm_react: data.pm_react ? data.pm_react : [],
                    kucun_cj_react: data.kucun_cj_react ? data.kucun_cj_react : [],
                    kucun_gg_react: data.kucun_gg_react ? data.kucun_gg_react : [],
                    kucun_xh_react: data.kucun_xh_react ? data.kucun_xh_react : [],
                    kucun_gys_react: data.kucun_gys_react ? data.kucun_gys_react : [],
                })
            })
    }

    delSel() {
        let that = this
        fetch('/Ysjsfhz/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    delSel2(id, e) {
        let that = this
        fetch('/Ysjsfhz/delSel2', {
            method: "POST",
            body: JSON.stringify({
                'id': id,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                if (data.status == 0) {
                    message.error(data.msg)
                    return
                }
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }

    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Ysjsfhz/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }

    onRangeChange(name, dates, dateStrings) {
        console.log(name, dates, dateStrings)
        var data = Object.assign({}, this.state.newcondition, {
            [name + '1']: dateStrings[0],
            [name + '2']: dateStrings[1]
        })
        if (data) {
            this.setState({
                newcondition: data
            })
        }
    }

    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getFullYear().toString(),
                month: new Date().getMonth() + 1,
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }

    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 60,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '日期',
                dataIndex: 'riqi1_str',
                key: 'riqi1_str',
                align: "center",
                fixed: 'left',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '结算日期',
                dataIndex: 'jsrq_str',
                key: 'jsrq_str',
                align: "center",
                fixed: 'left',
                width: 100,
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '品名',
                dataIndex: 'pm_str',
                key: 'pm_str',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '厂家',
                dataIndex: 'cj',
                key: 'cj',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '规格',
                dataIndex: 'gg',
                key: 'gg',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '型号',
                dataIndex: 'xinghao',
                key: 'xinghao',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '供应商',
                dataIndex: 'gys',
                key: 'gys',
                align: "center",
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '收入',
                dataIndex: 'sr',
                key: 'sr',
                children: [
                    {
                        title: '数量',
                        dataIndex: 'srsl',
                        key: 'srsl',
                        align: "center",
                        ellipsis: {
                            showTitle: false,
                        },
                        width: 100,
                        render: (row, record) => (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        ),
                    },
                    {
                        title: '单价',
                        dataIndex: 'srdj',
                        key: 'srdj',
                        align: "center",
                        ellipsis: {
                            showTitle: false,
                        },
                        width: 100,
                        render: (row, record) => (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        ),
                    },
                    {
                        title: '金额',
                        dataIndex: 'srje',
                        key: 'srje',
                        align: "center",
                        ellipsis: {
                            showTitle: false,
                        },
                        width: 100,
                        render: (row, record) => (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        ),
                    },
                ]
            },
            // {
            //     title: '发出数量',
            //     dataIndex: 'fcsl',
            //     key: 'fcsl',
            //     align: "center",
            //     ellipsis: {
            //         showTitle: false,
            //     },
            //     width:100,
            //     render: (row, record) => (
            //         <Tooltip placement="topLeft" title={row}>
            //             {row}
            //         </Tooltip>
            //     ),
            // },
            {
                title: '发出',
                dataIndex: 'sr',
                key: 'sr',
                children: [
                    {
                        title: '数量',
                        dataIndex: 'fcsl',
                        key: 'fcsl',
                        align: "center",
                        ellipsis: {
                            showTitle: false,
                        },
                        width: 100,
                        render: (row, record) => (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        ),
                    },
                    {
                        title: '单价',
                        dataIndex: 'fcdj',
                        key: 'fcdj',
                        align: "center",
                        ellipsis: {
                            showTitle: false,
                        },
                        width: 100,
                        render: (row, record) => (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        ),
                    },
                    {
                        title: '金额',
                        dataIndex: 'fcje',
                        key: 'fcje',
                        align: "center",
                        ellipsis: {
                            showTitle: false,
                        },
                        width: 100,
                        render: (row, record) => (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        ),
                    },
                ]
            },
            {
                title: '验收人',
                dataIndex: 'ysr',
                key: 'ysr',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车辆编号',
                dataIndex: 'clbh',
                key: 'clbh',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '车牌',
                dataIndex: 'cp',
                key: 'cp',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '外租泵单位',
                dataIndex: 'wzbdw_str',
                key: 'wzbdw_str',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '验收人',
                dataIndex: 'ysr',
                key: 'ysr',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '领用人',
                dataIndex: 'lyr',
                key: 'lyr',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '附件',
                dataIndex: 'fj',
                key: 'fj',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width: 80,
                render: (row, record) => (
                    <>
                        {row.length ?
                            <div style={{color: '#1890ff', cursor: "pointer"}} onClick={() => {
                                Modal.info({
                                    title: '附件',
                                    content: (
                                        <Space direction={"vertical"}>
                                            {row.map((item, index) => <>
                                                <a target={"_blank"} href={item.url}>{item.name}</a>
                                            </>)}
                                        </Space>
                                    ),
                                    onOk() {
                                    },
                                })
                            }}>
                                {row.length}
                            </div>
                            : null}
                    </>
                ),
            },
            {
                title: '备注',
                dataIndex: 'bz',
                key: 'bz',
                align: "center",
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作人',
                dataIndex: 'aidname',
                key: 'aidname',
                ellipsis: {
                    showTitle: false,
                },
                width: 120,
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={record.updatetime}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 150,
                fixed: 'right',
                render: (row, record) => {
                    return (
                        <>
                            <Space>
                                <Add id={record.id} refresh={this.getdata.bind(this)} setinfo={this.props.SET_INFO}/>
                                {store.getState().data.info.qx4 ? (store.getState().data.info.qx4 == 1 ?
                                    <Popconfirm
                                        title="确定删除?"
                                        onConfirm={this.delSel2.bind(this, record.id)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <a href="#" className="hongse">删除</a>
                                    </Popconfirm>
                                    : null) : null}
                            </Space>
                        </>
                    )
                },
            },
        ]
        const {tableloading, selectedRowKeys} = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            // hideSelectAll: true,
            preserveSelectedRowKeys: false,
            // renderCell: function (checked, record, index, originNode) {
            //     if (record.status != 1) {
            //         return originNode
            //     } else {
            //         return false
            //     }
            // },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between" align={'top'} wrap={false}>
                        <Space wrap>
                            <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year"
                                        value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null}/>
                            <Select placeholder="月份" style={{width: 120}}
                                    value={this.state.newcondition.month ? this.state.newcondition.month : 0}
                                    onChange={this.onSelChange.bind(this, 'month')} allowClear>
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <RangePicker allowEmpty={[true, true]} onChange={this.onRangeChange.bind(this, 'riqi1')}
                                         value={[(this.state.newcondition.riqi11 ? moment(this.state.newcondition.riqi11) : null), (this.state.newcondition.riqi12 ? moment(this.state.newcondition.riqi12) : null)]}
                                         placeholder={['日期', '日期']}/>

                            {/*<Select placeholder="品名"
                                    value={this.state.newcondition.pm ? this.state.newcondition.pm : null}
                                    onChange={this.onSelChange.bind(this, 'pm')} allowClear style={{width: 250}}
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.pm_react)}
                            </Select>*/}
                            <Input placeholder="品名" style={{width: 150}}
                                   value={this.state.newcondition.pm_keywords ? this.state.newcondition.pm_keywords : null}
                                   onChange={this.onChange.bind(this, 'pm_keywords')}/>
                            <Select placeholder="厂家" style={{width: 150}} allowClear showSearch
                                    optionFilterProp="children"
                                    value={this.state.newcondition.cj ? this.state.newcondition.cj : null}
                                    onChange={this.onSelChange.bind(this, 'cj')}>
                                {this.arrsel(this.state.kucun_cj_react)}
                            </Select>
                            <Select placeholder="规格" style={{width: 150}} allowClear showSearch
                                    optionFilterProp="children"
                                    value={this.state.newcondition.gg ? this.state.newcondition.gg : null}
                                    onChange={this.onSelChange.bind(this, 'gg')}>
                                {this.arrsel(this.state.kucun_gg_react)}
                            </Select>
                            <Select placeholder="型号" style={{width: 150}} allowClear showSearch
                                    optionFilterProp="children"
                                    value={this.state.newcondition.xh ? this.state.newcondition.xh : null}
                                    onChange={this.onSelChange.bind(this, 'xh')}>
                                {this.arrsel(this.state.kucun_xh_react)}
                            </Select>
                            <Select placeholder="供应商" style={{width: 250}} allowClear showSearch
                                    optionFilterProp="children"
                                    value={this.state.newcondition.gys ? this.state.newcondition.gys : null}
                                    onChange={this.onSelChange.bind(this, 'gys')}>
                                {this.arrsel(this.state.kucun_gys_react)}
                            </Select>
                            <Select placeholder="车牌"
                                    value={this.state.newcondition.chid ? this.state.newcondition.chid : null}
                                    onChange={this.onSelChange.bind(this, 'chid')} allowClear style={{width: 150}}
                                    showSearch optionFilterProp="children">
                                {this.arrsel(this.state.chid_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                            {store.getState().data.info.qx4 ? (store.getState().data.info.qx4 != -1 ?
                                <Kucun/>
                                : null) : null}
                        </Space>
                        {store.getState().data.info.qx4 ? (store.getState().data.info.qx4 == 1 ?
                            <Space>
                                <Add refresh={this.getdata.bind(this)}/>
                                <Dcdzd iswz={this.props.match.params.iswz ? this.props.match.params.iswz : 0} />
                            </Space>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    rowSelection={rowSelection}
                    scroll={{x: 2500, y: this.state.tableHeight ? this.state.tableHeight : null}}
                    className='table'
                    size="small"
                    summary={(pageData) => {
                        let totalsrje = 0;
                        let totalfcje = 0;
                        let totalkcje = 0;
                        pageData.forEach(({srje, fcje, kcje}) => {
                            totalsrje += Number(srje ? srje : 0);
                            totalfcje += Number(fcje ? fcje : 0);
                            totalkcje += Number(kcje ? kcje : 0);

                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={8} align='center'></Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}>{totalsrje.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}
                                                        align='center'>{totalfcje.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={16}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={17}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={18}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={19}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={20}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={21}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={22}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={23}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
                <div className='page page3'>
                    <div className='l'>
                        <Space wrap>
                            {hasSelected ?
                                <>
                                    {store.getState().data.info.qx4 ? (store.getState().data.info.qx4 == 1 ?
                                        <>
                                            <Jiesuan refresh={this.getdata.bind(this)} arrid={selectedRowKeys}/>
                                        </>
                                        : null) : null}
                                </>
                                : null}
                        </Space>
                    </div>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1}
                                pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50}
                                total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true}
                                showTotal={total => `共 ${total} 条数据`}/>
                </div>
            </>
        )
    }
}
export default (props) => <Index {...props} key={props.location.pathname}/>
