import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Switch, Space, Select, Input, Button, Table, Modal, Row, Col, Form, message, Checkbox, Radio, Pagination, Tag, Popconfirm, Tooltip, DatePicker, InputNumber } from 'antd';
import store from "../store/store.js";
import { FormOutlined } from '@ant-design/icons'
import moment from 'moment';
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newcondition: {
                year: new Date().getMonth() == 0 ? (new Date().getFullYear() - 1).toString() : new Date().getFullYear().toString(),
                month: new Date().getMonth() == 0 ? 12 : new Date().getMonth(),
            },
            oldcondition: {},
            list: [],
            tableloading: false,
            selectedRowKeys: [],
            count: 0,
            downloading: false,
            month_react: [
                { id: 1, name: '1月' },
                { id: 2, name: '2月' },
                { id: 3, name: '3月' },
                { id: 4, name: '4月' },
                { id: 5, name: '5月' },
                { id: 6, name: '6月' },
                { id: 7, name: '7月' },
                { id: 8, name: '8月' },
                { id: 9, name: '9月' },
                { id: 10, name: '10月' },
                { id: 11, name: '11月' },
                { id: 12, name: '12月' },
            ]
        };
        store.subscribe(() => {
            //   console.log('state状态改变了，新状态如下')
            //   console.log(store.getState().data.info.zhglqx)
        })
    }
    componentDidMount() {
        this.props.NAV_CHANGE('/gzjs'+(this.props.match.params.iswz ? '/' + this.props.match.params.iswz : ''), '工资计算')
        this.getdata(1)
        this.tableHeight()
        window.addEventListener('resize',this.tableHeight)
    }
    tableHeight = () => {
        console.log('屏幕宽度变化')
        let that = this
        let bgheight = document.querySelector('.site-layout-background').clientHeight
        let selheight = document.querySelector('.sellist').clientHeight
        let yheight = bgheight - 295 - selheight
        that.setState({
            tableHeight:yheight
        })
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.tableHeight)
    }
    onTimeChange(name, date, dateString) {
        let that = this
        console.log(name, date, dateString)
        let data = Object.assign({}, this.state.newcondition, { [name]: dateString })
        this.setState({
            newcondition: data
        })
    }
    onSelChange(name, value) {
        let that = this
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onSwChange(name, value) {
        let that = this
        console.log(name, value)
    }
    onChange(name, e) {
        let that = this
        let value = e.target.value
        console.log(name, value)
        let data = Object.assign({}, this.state.newcondition, { [name]: value })
        this.setState({
            newcondition: data
        })
    }
    onTableChange = (index, name, value) => {
        let that = this
        console.log(index, name, value)
        let list = that.state.list.concat()
        list[index][name] = value
        that.setState({
            list: list
        })
    }
    onTableChange2 = (index, name, e) => {
        let that = this
        let value = e.target.value
        // console.log(index, name, value)
        let list = that.state.list.concat()
        list[index][name] = value
        that.setState({
            list: list
        })
    }
    onSelectChange = (selectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    pagechange(page, pagesize) {
        let that = this
        console.log(page, pagesize)
        let data = Object.assign({}, this.state.oldcondition, { current: page, pagesize: pagesize })
        setTimeout(() => {
            this.setState({
                oldcondition: data
            })
            that.getdata(0, null)
        })
    }
    arrsel(arr) {
        if (arr) {
            return arr.map((item, index) =>
                <Option value={item.id} key={index} disabled={(item.isdel == 1 || item.status == 2) ? true : false}>{item.name}</Option>
            )
        }
    }
    getdata(subtype = 0, e) {
        console.log(subtype)
        let that = this
        that.setState({
            tableloading: true
        })
        if (subtype == 1) {
            var arr = that.state.newcondition
            let old = that.state.oldcondition
            arr = Object.assign({}, arr, { current: 1, pagesize: old.pagesize })
            that.setState({
                oldcondition: arr
            })
        } else {
            var arr = that.state.oldcondition
        }
        arr.iswz = that.props.match.params.iswz ? that.props.match.params.iswz : 0
        fetch('/Gzjs/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                that.setState({
                    list: data.data,
                    tableloading: false,
                    count: Number(data.count)
                })
            })
    }
    delSel() {
        let that = this
        fetch('/Gzjs/delSel', {
            method: "POST",
            body: JSON.stringify({
                'arrid': this.state.selectedRowKeys,
            })
        })
            .then(res => res.json())
            .then(data => {
                // console.log(data)
                this.pagechange(1, this.state.oldcondition.pagesize)
                that.setState({
                    selectedRowKeys: []
                })
            })
    }
    download() {
        let that = this
        that.setState({
            downloading: true
        })
        var arr = that.state.oldcondition
        arr['download'] = 1;
        // console.log(arr)
        fetch('/Gzjs/index', {
            // post提交
            method: "POST",
            body: JSON.stringify(arr)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                try {
                    var elemIF = document.createElement("iframe");
                    elemIF.src = data.url;
                    elemIF.style.display = "none";
                    document.body.appendChild(elemIF);
                } catch (e) {
                    alert("下载异常！");
                }
                that.setState({
                    downloading: false
                })
            })
    }
    editRow = (index, state, e) => {
        let that = this
        let list = that.state.list.concat()
        list[index]['isedit'] = state
        console.log(list)
        that.setState({
            list: list
        })
        if (state == 0) {
            fetch('/Gzjs/doview', {
                // post提交
                method: "POST",
                body: JSON.stringify(list[index])
            })
                .then(res => res.json())
                .then(data => {

                    if (data.status == 0) {
                        message.error(data.msg)
                        return
                    }
                    if (data.status == 1) {
                        message.success(data.msg)
                        that.getdata()
                    }
                })
        }
    }
    reset() {
        let that = this
        that.setState({
            newcondition: {
                year: new Date().getMonth() == 0 ? (new Date().getFullYear() - 1).toString() : new Date().getFullYear().toString(),
                month: new Date().getMonth() == 0 ? 12 : new Date().getMonth(),
            },
            oldcondition: {},
        })
        setTimeout(() => {
            that.getdata(1, null)
        })
    }
    render() {
        const columns = [
            {
                title: '序号',
                dataIndex: 'xh',
                key: 'xh',
                align: "center",
                width: 80,
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '人员',
                dataIndex: 'name',
                key: 'name',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '岗位',
                dataIndex: 'gw_str',
                key: 'gw_str',
                fixed: 'left',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '工资方量',
                dataIndex: 'gzfl',
                key: 'gzfl',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '单人上泵方量',
                dataIndex: 'drsbfl',
                key: 'drsbfl',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '提成',
                dataIndex: 'tc',
                key: 'tc',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '基本工资',
                dataIndex: 'jbgz',
                key: 'jbgz',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.isedit == 1) {
                        return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'jbgz')} />
                    } else {
                        return (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        )
                    }
                }
            },
            {
                title: '出勤天数',
                dataIndex: 'cqts',
                key: 'cqts',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '加班费',
                dataIndex: 'jbf',
                key: 'jbf',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '扣款',
                dataIndex: 'kk',
                key: 'kk',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '奖金',
                dataIndex: 'jj',
                key: 'jj',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.isedit == 1) {
                        return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'jj')} />
                    } else {
                        return (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        )
                    }
                }
            },
            {
                title: '社保',
                dataIndex: 'sb',
                key: 'sb',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.isedit == 1) {
                        return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'sb')} />
                    } else {
                        return (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        )
                    }
                }
            },
            {
                title: '工资总额',
                dataIndex: 'gzze',
                key: 'gzze',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '实发金额',
                dataIndex: 'sfje',
                key: 'sfje',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.isedit == 1) {
                        return <Input value={row} placeholder="请输入" onChange={this.onTableChange2.bind(this, index, 'beizhu')} />
                    } else {
                        return (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        )
                    }
                }
            },
            {
                title: '罚款',
                dataIndex: 'fk',
                key: 'fk',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.isedit == 1) {
                        return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'fk')} />
                    } else {
                        return (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        )
                    }
                }
            },
            {
                title: '其他扣款',
                dataIndex: 'qtkk',
                key: 'qtkk',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record, index) => {
                    if (record.isedit == 1) {
                        return <InputNumber value={row} placeholder="请输入" onChange={this.onTableChange.bind(this, index, 'qtkk')} />
                    } else {
                        return (
                            <Tooltip placement="topLeft" title={row}>
                                {row}
                            </Tooltip>
                        )
                    }
                }
            },
            {
                title: '最终实发',
                dataIndex: 'zzsf',
                key: 'zzsf',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '开户行',
                dataIndex: 'khh',
                key: 'khh',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '银行卡号',
                dataIndex: 'yhkh',
                key: 'yhkh',
                ellipsis: {
                    showTitle: false,
                },
                render: (row, record) => (
                    <Tooltip placement="topLeft" title={row}>
                        {row}
                    </Tooltip>
                ),
            },
            {
                title: '操作',
                dataIndex: 'cz',
                key: 'cz',
                align: 'center',
                width: 80,
                fixed: 'right',
                render: (row, record, index) => {
                    return (
                        <>
                            {store.getState().data.info.qx13 ? (store.getState().data.info.qx13 == 1 ?
                                <Space>
                                    {record.isedit == 1 ? <a href='javascript:void(0)' onClick={this.editRow.bind(this, index, 0)}>完成</a> : <a href='javascript:void(0)' onClick={this.editRow.bind(this, index, 1)}>编辑</a>}
                                </Space>
                                : null) : null}
                        </>
                    )
                },
            },
        ]
        const { tableloading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange.bind(this),
            hideSelectAll: true,
            preserveSelectedRowKeys: false,
            renderCell: function (checked, record, index, originNode) {
                if (record.status != 1) {
                    return originNode
                } else {
                    return false
                }
            },
        };
        const hasSelected = selectedRowKeys.length > 0;
        return (
            <>
                <div className='sellist'>
                    <Row justify="space-between">
                        <Space wrap>
                            <DatePicker placeholder="年份" onChange={this.onTimeChange.bind(this, 'year')} picker="year" value={this.state.newcondition.year ? moment(this.state.newcondition.year) : null} allowClear={false} />
                            <Select placeholder="月份" style={{ width: 120 }} value={this.state.newcondition.month ? this.state.newcondition.month : null} onChange={this.onSelChange.bind(this, 'month')} allowClear={false} >
                                {this.arrsel(this.state.month_react)}
                            </Select>
                            <Button type="primary" onClick={this.getdata.bind(this, 1)}>搜索</Button>
                            <Button type="primary" className='grey' onClick={this.reset.bind(this)}>重置</Button>
                        </Space>
                        {store.getState().data.info.qx14 ? (store.getState().data.info.qx14 == 1 ?
                            <Space wrap>
                                <Button type="primary" className='blue' onClick={this.download.bind(this)} loading={this.state.downloading}>下载</Button>
                            </Space>
                            : null) : null}
                    </Row>
                </div>
                <Table
                    bordered
                    columns={columns}
                    sticky={false}
                    dataSource={this.state.list}
                    pagination={false}
                    loading={tableloading}
                    // rowSelection={rowSelection}
                    scroll={{ x: 2000,y:this.state.tableHeight?this.state.tableHeight:null }}
                    className='table'
                    size="small"
                    summary={(pageData) => {
                        let totalgzfl = 0;
                        let totaldrsbfl = 0;
                        let totaltc = 0;
                        let totaljbgz = 0;
                        let totalcqts = 0;
                        let totaljbf = 0;
                        let totalkk = 0;
                        let totaljj = 0;
                        let totalsb = 0;
                        let totalgzze = 0;
                        let totalsfje = 0;
                        let totalfk = 0;
                        let totalqtkk = 0;
                        let totalzzsf = 0;

                        let totalgzfl1 = 0;
                        let totaldrsbfl1 = 0;
                        let totaltc1 = 0;
                        let totaljbgz1 = 0;
                        let totalcqts1 = 0;
                        let totaljbf1 = 0;
                        let totalkk1 = 0;
                        let totaljj1 = 0;
                        let totalsb1 = 0;
                        let totalgzze1 = 0;
                        let totalsfje1 = 0;
                        let totalfk1 = 0;
                        let totalqtkk1 = 0;
                        let totalzzsf1 = 0;

                        let totalgzfl2 = 0;
                        let totaldrsbfl2 = 0;
                        let totaltc2 = 0;
                        let totaljbgz2 = 0;
                        let totalcqts2 = 0;
                        let totaljbf2 = 0;
                        let totalkk2 = 0;
                        let totaljj2 = 0;
                        let totalsb2 = 0;
                        let totalgzze2 = 0;
                        let totalsfje2 = 0;
                        let totalfk2 = 0;
                        let totalqtkk2 = 0;
                        let totalzzsf2 = 0;
                        pageData.forEach(({ gzfl, drsbfl, tc, jbgz, cqts,jbf, kk, jj, sb, gzze, sfje, fk, qtkk, zzsf, gw }) => {
                            totalgzfl += Number(gzfl ? gzfl : 0);
                            totaldrsbfl += Number(drsbfl ? drsbfl : 0);
                            totaltc += Number(tc ? tc : 0);
                            totaljbgz += Number(jbgz ? jbgz : 0);
                            totalcqts += Number(cqts ? cqts : 0);
                            totaljbf += Number(jbf ? jbf : 0);
                            totalkk += Number(kk ? kk : 0);
                            totaljj += Number(jj ? jj : 0);
                            totalsb += Number(sb ? sb : 0);
                            totalgzze += Number(gzze ? gzze : 0);
                            totalsfje += Number(sfje ? sfje : 0);
                            totalfk += Number(fk ? fk : 0);
                            totalqtkk += Number(qtkk ? qtkk : 0);
                            totalzzsf += Number(zzsf ? zzsf : 0);
                            if (gw == 1 || gw == 3) {
                                totalgzfl1 += Number(gzfl ? gzfl : 0);
                                totaldrsbfl1 += Number(drsbfl ? drsbfl : 0);
                                totaltc1 += Number(tc ? tc : 0);
                                totaljbgz1 += Number(jbgz ? jbgz : 0);
                                totalcqts1 += Number(cqts ? cqts : 0);
                                totaljbf1 += Number(jbf ? jbf : 0);
                                totalkk1 += Number(kk ? kk : 0);
                                totaljj1 += Number(jj ? jj : 0);
                                totalsb1 += Number(sb ? sb : 0);
                                totalgzze1 += Number(gzze ? gzze : 0);
                                totalsfje1 += Number(sfje ? sfje : 0);
                                totalfk1 += Number(fk ? fk : 0);
                                totalqtkk1 += Number(qtkk ? qtkk : 0);
                                totalzzsf1 += Number(zzsf ? zzsf : 0);
                            } else if (gw == 2) {
                                totalgzfl2 += Number(gzfl ? gzfl : 0);
                                totaldrsbfl2 += Number(drsbfl ? drsbfl : 0);
                                totaltc2 += Number(tc ? tc : 0);
                                totaljbgz2 += Number(jbgz ? jbgz : 0);
                                totalcqts2 += Number(cqts ? cqts : 0);
                                totaljbf2 += Number(jbf ? jbf : 0);
                                totalkk2 += Number(kk ? kk : 0);
                                totaljj2 += Number(jj ? jj : 0);
                                totalsb2 += Number(sb ? sb : 0);
                                totalgzze2 += Number(gzze ? gzze : 0);
                                totalsfje2 += Number(sfje ? sfje : 0);
                                totalfk2 += Number(fk ? fk : 0);
                                totalqtkk2 += Number(qtkk ? qtkk : 0);
                                totalzzsf2 += Number(zzsf ? zzsf : 0);
                            }
                        });
                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={3} align="center">司机合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>{totalgzfl1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>{totaldrsbfl1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{totaltc1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>{totaljbgz1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>{totalcqts1}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>{totaljbf1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>{totalkk1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}>{totaljj1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}>{totalsb1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}>{totalgzze1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}>{totalsfje1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}>{totalfk1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}>{totalqtkk1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={16}>{totalzzsf1.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={17}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={18}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={19}></Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={3} align="center">管理合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>{totalgzfl2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>{totaldrsbfl2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{totaltc2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>{totaljbgz2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>{totalcqts2}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>{totaljbf2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>{totalkk2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}>{totaljj2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}>{totalsb2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}>{totalgzze2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}>{totalsfje2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}>{totalfk2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}>{totalqtkk2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={16}>{totalzzsf2.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={17}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={18}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={19}></Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>{totalgzfl.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>{totaldrsbfl.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{totaltc.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>{totaljbgz.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>{totalcqts}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={7}>{totaljbf.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={8}>{totalkk.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={9}>{totaljj.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={10}>{totalsb.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={11}>{totalgzze.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={12}>{totalsfje.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={13}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={14}>{totalfk.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={15}>{totalqtkk.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={16}>{totalzzsf.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={17}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={18}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={19}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        );
                    }}
                />
                {/* <div className='page'>
                    <Pagination current={this.state.oldcondition.current ? this.state.oldcondition.current : 1} pageSize={this.state.oldcondition.pagesize ? this.state.oldcondition.pagesize : 50} total={this.state.count} onChange={this.pagechange.bind(this)} showSizeChanger={true} showTotal={total => `共 ${total} 条数据`} />
                </div> */}
            </>
        )
    }
}
export default (props) => <Index {...props} key={props.location.pathname}/>
